@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.75rem;
  @include tab() {
    margin-top: 5.125rem;
  }

  .ctaBannerContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    align-items: last baseline;

    width: 100%;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }

    :global {
      .headingElement {
        grid-column: span 2;
        @include tab() {
          grid-column: span 1;
        }
      }
    }
  }
  .ctaBannerTitle {
    display: flex;
    flex-direction: column;
    span {
      color: $dark;
    }
    @include tab() {
      text-align: center;
      align-items: center;
    }
  }

  .description {
    text-align: left;
    width: 100%;

    @include tab() {
      text-align: center;
    }
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .bannerImageContainer {
    position: relative;
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    height: 33.75rem;

    @include mob() {
      height: 29.25rem;
    }
  }

  .bannerImageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.1s ease;
  }

  .bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
